<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
        <img
          :src="logo"
          :onerror="defaultLogo"
          class="sidebar-logo"
        />
        <!-- TODO -->
        <!-- <h1 v-else class="sidebar-title">
          {{ title }}
        </h1> -->
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img
          :src="logo"
          :onerror="defaultLogo"
          class="sidebar-logo"
        />
        <h1 class="sidebar-title">
          {{ title }}
        </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      getLogo: "",
    };
  },
  computed: {
    logo() {
      const image = require("@/assets/image/logo.png");
      return image;
    },
    title() {
      return "Ledger";
    },
    defaultLogo() {
      // const { logo } = this.oem;
      // return `this.src="${logo}"`;
      return `this.src="${null}"`;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 75px;
  line-height: 50px;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 8px;
      margin-left: 20px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      line-height: 50px;
      font-size: 18px;
      vertical-align: middle;
      opacity: 0.79;
      font-weight: 500;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
      margin-left: 0px;
    }
  }
}
</style>
