import AccountApi from "./account";
import BillTypeApi from "./bill-type";
import BillApi from "./bill";
import AuthApi from "./auth";

export default {
  AccountApi,
  BillTypeApi,
  BillApi,
  AuthApi
};