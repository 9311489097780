<template>
  <el-menu-item :index="path">
    <i :class="icon" v-if="icon" />
    <span slot="title">{{ name }}</span>
  </el-menu-item>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    icon: {
      type: String,
      default: () => {
        return "";
      },
    },
    path: {
      type: String,
      default: () => {
        return "";
      },
    },
    name: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
