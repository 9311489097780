<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
body {
  /* background-color: pink; */
  background-color: rgb(235, 235, 235);
  margin: 0px;
  padding: 0px;
}
#app {
  width: 100vw;
  height: 100vh;
}

/* 滚动条 */
/* 滚动条凹槽的颜色，还可以设置边框属性 */
::-webkit-scrollbar-track {
  background-color: #fff;
  /* 滚动条凹槽悬浮 */
  &:hover {
    background-color: #fff;
  }
}

/* 滚动条的宽度、高度，对应竖滚动条、横滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #e8e8e8;
  border-radius: 2px;
  /* 滚动条悬浮 */
  &:hover {
    background-color: #d9d9d9;
  }
}
</style>
