import axios from "axios";
const perfix = "/api/Bill";

/**
 * 仅新增一条账单（不修改卡余额）
 * @param {object} params 
 * @returns 
 */
const add = async (params) => {
  return await axios.post(perfix + '/create', params)
};

/**
 * 新增一条账单（并且修改卡余额）
 * @param {object} params 
 * @returns 
 */
const addSync = async (params) => {
  return await axios.post(perfix + '/create-sync', params)
};

/**
 * 批量新增账单（并且修改卡余额）
 * @param {array} billList 
 * @returns 
 */
const batchAdd = async (billList) => {
  return await axios.post(perfix + '/batch-add-bill', billList)
};

/**
 * 批量编辑账单（不修改卡余额）
 * @param {array} billList 
 * @returns 
 */
const batchEdit = async (billList) => {
  return await axios.post(perfix + '/batch-edit-bill', billList)
};

/**
 * 仅修改一条账单（不修改卡余额）
 * @param {object} params 
 * @returns 
 */
const update = async (params) => {
  return await axios.put(perfix + '/update', params)
};

/**
 * 修改一条账单（并且修改卡余额）
 * @param {object} params 
 * @returns 
 */
const updateSync = async (params) => {
  return await axios.put(perfix + '/update-sync', params)
};

const query = async ({
  page,
  size,

  date,
  accountId,
  method,
  billType,
  description,
}) => {
  let urlParam =
    "page=" + page + "&" +
    "size=" + size;

  if (date) {
    urlParam += "&date=" + date
  }
  if (accountId) {
    urlParam += "&accountId=" + accountId
  }
  if (method) {
    urlParam += "&method=" + method
  }
  if (billType) {
    urlParam += "&billType=" + billType
  }
  if (description) {
    urlParam += "&description=" + description
  }
  return await axios.get(perfix + "/query?" + urlParam);
};

/**
 * 仅删除此条账单（不修改卡余额）
 * @param {string} id 
 * @returns 
 */
const remove = async id => {
  return await axios.delete(perfix + "/delete/" + id);
}

/**
 * 删除此条账单（并且修改卡余额）
 * @param {string} id 
 * @returns 
 */
const removeSync = async id => {
  return await axios.delete(perfix + "/delete-sync/" + id);
}

const queryBillByTimeFilter = async (param) => {
  return await axios.post(perfix + "/query-by-time-filter", param);
}

/**
 * 获取账户年度总览
 * @param {{date:string,accountId:string}} param 
 * @returns 
 */
const getAccountGeneralView = async (param) => {
  return await axios.post(perfix + "/get-account-general-view", param);
}

/**
 * 获取账户的月度账单
 * @param {{date:string,accountId:string}} param 
 * @returns 
 */
const getAccountMonthView = async (param) => {
  return await axios.post(perfix + "/get-account-month-view", param);
}

/**
 * bill表的请求方法
 */
export default {
  add,
  addSync,
  batchAdd,
  batchEdit,
  update,
  updateSync,
  query,
  remove,
  removeSync,
  queryBillByTimeFilter,
  getAccountGeneralView,
  getAccountMonthView
};