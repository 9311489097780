import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import VueRouter from "vue-router";
Vue.use(VueRouter);
import routerList from "@/routes";
const router = new VueRouter({
  mode: 'history', // 路由的history模式（普遍使用）可以去掉url的#，默认是hash，带#
  routes: routerList
});
router.beforeEach((to, from, next) => {
  if (to.meta.name) {
    document.title = to.meta.name;
  } else {
    document.title = "Ledger";
  }
  next();
});

let vue = new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

import axios from "axios";
import Cookies from "js-cookie";
axios.interceptors.request.use(
  request => {
    const Authorization = Cookies.get("Authorization") || "";
    request.headers["Authorization"] = Authorization ? "Bearer " + Authorization : "";
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    const { data } = response;
    return data;
  },
  error => {
    const { response } = error;
    const { status, data } = response;
    const { message = "" } = data;
    switch (status) {
      case 401: {
        vue.$message.error(message || "没有合法的令牌，请重新登录");
        if (vue.$route.fullPath !== "/") {
          vue.$router.push("/");
        }
        Cookies.remove("Authorization");
        break;
      }
      case 500: {
        vue.$message.error(message || "服务异常");
        break;
      }
      case 501: {
        vue.$message.error(message || "服务异常");
        break;
      }
      case 502: {
        vue.$message.error(message || "服务异常");
        break;
      }
      case 503: {
        vue.$message.error(message || "服务异常");
        break;
      }
      default:
        break;
    }
    return Promise.reject(error);
  },
);
