<template>
  <div :class="classObj" class="app-wrapper">
    <Sidebar class="sidebar-container" />
    <div
      ref="main"
      :class="{ hasTagsView: true }"
      class="main-container"
      @transitionend.self="handleTransitionend"
    >
      <div :class="{ 'fixed-header': false }" class="nav-main">
        <Navbar />
        <!-- <Breadcrumb id="breadcrumb-container" class="breadcrumb-container" /> -->
        <div class="page-header">
          <div class="bread-crumb">
            <div
              class="bread-crumb-item"
              v-for="(breadCrumbItem, breadCrumbIndex) in titleList"
              :key="breadCrumbIndex"
            >
              <i class="el-icon-arrow-right" v-if="breadCrumbIndex !== 0"></i>
              <div
                class="bread-crumb-item-button"
                @click="handleClickBread(breadCrumbItem)"
              >
                {{ breadCrumbItem.name }}
              </div>
            </div>
          </div>
          <div class="page-title">
            {{ title }}
          </div>
        </div>
      </div>
      <AppMain />
      <!-- <RightPanel v-if="showSettings" /> -->
    </div>
  </div>
</template>

<script>
import { AppMain, Navbar, Sidebar } from "./components";
// import routerList from "@/routes";
import ResizeMixin from "./mixin/ResizeHandler";

export default {
  name: "Layout",
  components: {
    AppMain,
    // Breadcrumb,
    Navbar,
    // RightPanel,
    Sidebar,
  },
  mixins: [ResizeMixin],
  computed: {
    classObj() {
      return {
        showSettings: false,
        hideSidebar: false,
        openSidebar: true,
        withoutAnimation: true,
      };
    },
    title() {
      let t = "";
      const route = this.$route;
      if (route) {
        t = route.name;
      }
      return t;
    },
    titleList() {
      let t = [];
      const route = this.$route;
      if (route && route.matched && route.matched.length) {
        t = route.matched;
      }
      return t;
    },
  },
  watch: {
    $route() {
      // this.$store.dispatch("page/resetPageHeader");
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setSidebarWidth();
      window.addEventListener("resize", this.resizeHandle);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandle);
  },
  methods: {
    handleTransitionend(event) {
      if (event.propertyName == "margin-left") {
        this.setSidebarWidth();
      }
    },
    setSidebarWidth() {
      // this.$store.dispatch("page/setMainWidth", this.$refs.main.clientWidth);
    },
    resizeHandle() {
      this.setSidebarWidth();
    },

    /**
     * 点击了面包屑的项
     * @param {object} breadCrumbItem
     */
    handleClickBread(breadCrumbItem) {
      const { path, redirect } = breadCrumbItem;
      const route = this.$route;
      const { path: nowPath } = route;
      if (redirect) {
        if (redirect === nowPath) {
          return;
        }
        this.$router.push({
          path: redirect,
        });
      } else {
        if (path === nowPath) {
          return;
        }
        this.$router.push({
          path,
        });
      }
    },
  },
};
</script>

<style lang="scss">
// 这里不用scoped，layout组件部署后是项目唯一的
@import "./styles/variables.scss";
@import "./styles/index.scss";
</style>
