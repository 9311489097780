<template>
  <div class="navbar">
    <div class="right-menu">
      <div class="avatar-wrapper">
        <!-- <img
          :src="'data:image/png;base64,' + avatar"
          class="user-avatar"
          :onerror="defaultLogo"
          @click="gotoPersonalInfo"
        /> -->
        <img
          :src="'data:image/png;base64,' + avatar"
          :onerror="defaultAvatar"
          class="user-avatar"
          @click="gotoPersonalInfo"
          v-if="false"
        />
        <span class="name" v-if="false">欢迎</span>
      </div>
      <div class="divide-bar"></div>

      <el-popover
        placement="bottom"
        title="令牌状态"
        width="200"
        trigger="click"
      >
        <div class="token-expire-wrapper" slot="reference">
          <div class="token-progress">
            <el-progress
              class="token-progress-item"
              type="circle"
              :percentage="percentage"
              :color="progressColor"
              :width="28"
              :height="28"
              :stroke-width="3"
              :show-text="false"
            />
          </div>
          <div class="token-time">
            {{ leftTime }}
          </div>
        </div>

        <div class="token-renewal-dialog">
          <div class="token-renewal-dialog-progress">
            <el-progress
              class="token-progress-item"
              type="circle"
              :percentage="percentage"
              :color="progressColor"
              :width="160"
              :height="160"
              :stroke-width="6"
              :format="
                () => {
                  return this.leftTime;
                }
              "
            />
          </div>
          <div class="token-renewal-dialog-tip">
            当令牌即将过期时，可以通过续签来继续增加令牌的时间
          </div>
          <div class="token-renewal-dialog-footer">
            <el-button
              size="mini"
              @click="handleRenewalJwt"
              :loading="renewalLoading"
              >续签</el-button
            >
          </div>
        </div>
      </el-popover>

      <div class="divide-bar"></div>
      <div class="logout-wrapper" @click="logout">
        <div class="logout-icon">
          <i class="el-icon-switch-button" />
        </div>
        <span class="logout-text"> 退出 </span>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import Api from "@/api";

export default {
  name: "Navbar",
  computed: {
    leftTime() {
      let lt = "--:--:--";
      if (this.leftSecond) {
        const h = Math.floor(this.leftSecond / 3600);
        const m = Math.floor((this.leftSecond / 60) % 60);
        const mm = m < 10 ? "0" + m : m;
        const s = Math.floor(this.leftSecond % 60);
        const ss = s < 10 ? "0" + s : s;
        lt = `${h}:${mm}:${ss}`;
      }
      return lt;
    },
    percentage() {
      let result = 0;
      if (this.leftSecond) {
        result = this.leftSecond / 36;
      }

      if (result <= 0) {
        result = 0;
      }
      return result;
    },
    progressColor() {
      let color = "#cccccc";
      if (this.percentage) {
        if (this.percentage > 20) {
          color = "rgb(19, 206, 102)";
        } else if (this.percentage > 10) {
          color = "rgb(230, 162, 60)";
        } else {
          color = "rgb(255, 73, 73)";
        }
      }
      return color;
    },
  },
  data() {
    return {
      avatar: "",
      defaultAvatar:
        "this.src='" + require("@/assets/image/default-avatar.png") + "'",
      leftSecond: null, // 剩余多少秒 通过setTimeout更新
      timer: null, // setTimeout
      expireTime: null, // jwt里的过期时间 时间戳
      renewalLoading: false,
    };
  },
  async mounted() {
    await this.handleGetExpireTime();
    this.setTimer();
  },
  methods: {
    logout() {
      this.$confirm("是否退出当前账号？", "退出", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          Cookies.remove("Authorization");
          this.$router.push(`/`);
        })
        .catch(() => {});
    },
    /**
     * 跳转到个人信息页
     * TODO：可配置
     */
    gotoPersonalInfo() {
      this.$router.push("/");
    },
    setTimer() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.expireTime) {
          const date = new Date();
          const nowTime = date.getTime() / 1000;
          this.leftSecond = (this.expireTime - nowTime).toFixed(0);
        }
        this.setTimer();
      }, 1000);
    },
    /**
     * 获取jwt的过期时间戳
     */
    async handleGetExpireTime() {
      const jwt = Cookies.get("Authorization");
      let exp = "";
      if (jwt) {
        const payloadB64 = jwt.split(".")[1];
        if (payloadB64) {
          const payloadStr = atob(payloadB64);
          if (payloadStr) {
            const payload = JSON.parse(payloadStr);
            exp = payload.exp;
          }
        }
      }

      this.expireTime = exp;
    },
    /**
     * 申领新的jwt
     */
    async handleRenewalJwt() {
      if (this.percentage > 20) {
        this.$message.warning(
          "时间还很多，等到剩下20%（进度条变黄）的时候再来续签"
        );
      } else {
        this.renewalLoading = true;
        const res = await Api.AuthApi.renewal().finally(() => {
          this.renewalLoading = false;
        });
        if (res && res.code === 0) {
          const { access_token = "" } = res.data;
          if (access_token.startsWith("ey")) {
            const jwtPayloadBase64 = access_token.split(".")[1];
            const jwtPayloadStr = atob(jwtPayloadBase64);
            const jwtPayload = JSON.parse(jwtPayloadStr);
            const nowTime = new Date().getTime(); // getTime() 是 精确到毫秒的时间戳
            const millisecond = jwtPayload.exp * 1000 - nowTime; // 返回的值和现在的时间的差别 毫秒
            const expires = new Date(new Date() * 1 + millisecond); // 生成一个新的时间
            Cookies.set("Authorization", access_token, {
              expires,
            });

            this.handleGetExpireTime();

            this.$message.success("续签成功!");
          } else if (access_token.startsWith("error:")) {
            this.$message.warning(access_token.replace("error:", ""));
          } else {
            this.$message.warning("续签失败！无令牌返回，或令牌格式错误");
          }
        } else {
          this.$message.error("续签失败");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  float: right;
  height: 75px;
  font-size: 14px;
  line-height: 75px;

  .hamburger-container {
    padding: 0 12px;
    height: 100%;
    line-height: 46px;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .right-menu {
    color: #666;
    display: flex;
    align-items: center;

    .avatar-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: 20px;
      cursor: pointer;

      .user-avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 8px;
        border: 2px solid #ccc;
      }
    }

    .divide-bar {
      background: linear-gradient(
        rgb(228, 228, 228),
        rgb(192, 192, 192),
        rgb(228, 228, 228)
      );
      width: 1px;
      height: 30px;
    }

    .token-expire-wrapper {
      display: flex;
      user-select: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 75px;
      cursor: pointer;
      user-select: none;

      .token-progress {
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        .token-progress-item {
          width: 100%;
          height: 100%;
        }
      }
      .token-time {
        margin-top: 3px;
        font-size: 12px;
        height: 14px;
        font-family: math;
        display: flex;
        user-select: none;
        justify-content: center;
        align-items: center;
      }
    }
    .token-expire-wrapper:hover {
      background-color: rgb(247, 247, 247);
    }

    .logout-wrapper {
      transition: all 0.2s;
      display: flex;
      cursor: pointer;
      user-select: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 75px;

      .logout-icon {
        height: 18px;
        width: 18px;
        > i {
          font-size: 18px;
          height: 100%;
          width: 100%;
        }
      }
      .logout-text {
        margin-top: 2px;
        font-size: 16px;
      }
    }
    .logout-wrapper:hover {
      background-color: rgb(247, 247, 247);
      color: #f00;
    }
  }
}

.token-renewal-dialog {
  width: 200px;
  user-select: none;
  .token-renewal-dialog-progress {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .token-renewal-dialog-tip {
    margin-bottom: 10px;
  }
  .token-renewal-dialog-footer {
    height: 40px;
    border-top: 1px solid rgb(211, 211, 211);
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
</style>
