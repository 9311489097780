import axios from "axios";
const perfix = "/api/Auth";

/**
 * 用户登录
 * @param {Object} param 
 * @returns 
 */
const login = async (param) => {
	const {
		password = ""
	} = param;

	return await axios.post(perfix + '/login', {
		password
	})
};

/**
 * 用户续签
 * @returns 
 */
const renewal = async () => {
	return await axios.post(perfix + '/renewal')
};

/**
 * 检查是否有存在密码
 * @returns {Booleam} 是否有存在密码
 */
const homeCheck = async () => {
	return await axios.post(perfix + '/home-check')
};

/**
 * 从服务器生成公私钥对，并且获取公钥
 * @param {string} usage 申请公钥的用处 "login" | "register" | "change"
 * @returns 公钥
 */
const getPublicKey = async (usage = "") => {
	return await axios.get(perfix + "/get-public-key?usage=" + usage)
}

/**
 * 用户保存密码
 * @param {Object} param 
 * @returns 
 */
const savePassword = async (param) => {
	return await axios.post(perfix + "/save-password", param);
};

export default {
	homeCheck,
	login,
	renewal,
	getPublicKey,
	savePassword
};