<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    cachedViews() {
      return [];
    },
    key() {
      return this.$route.fullPath;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  /* 135= navbar  135  */
  height: calc(100vh - 75px);
  width: 100%;
  position: relative;
  overflow: hidden;
  // border: 1px solid orange;
}

// .fixed-header + .app-main {
//   padding-top: 135px;
// }

.hasTagsView {
  .app-main {
    /* 169 = navbar + tags-view = 135 + 34 */
    min-height: calc(100vh - 169px);
  }

  .fixed-header + .app-main {
    padding-top: 169px;
  }
}

.fade-enter-active,
.fade-leave-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-active {
  -webkit-transform: opacity 0.28s;
  transform: opacity 0.28s;
}

.fade-transform-enter-active,
.fade-transform-leave-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
.component-container {
  background-color: rgb(235, 235, 235);
  width: calc(100% - 42px);
  height: calc(100% - 42px);
  padding: 21px;
  .content-container {
    background-color: white;
    height: 100%;
    width: 100%;
  }
}
</style>
