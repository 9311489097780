<template>
  <div
    :class="{ 'has-logo': showLogo }"
    @transitionend.self="handleTransitionend"
  >
    <SidebarLogo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        default-active="2"
        @select="handleSelect"
        @open="handleOpen"
        @close="handleClose"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <div v-for="(item, index) in menuList" :key="index">
          <MenuItemGroup v-if="item.children && item.children.length" :item="item" />
          <MenuItem v-else-if="!item.hideInMenu" :name="item.name" :path="item.path" :icon="item.icon"/>
        </div>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import SidebarLogo from "./SidebarLogo";
import MenuItem from "./MenuItem";
import MenuItemGroup from "./MenuItemGroup";
import routerList from "@/routes";
export default {
  name: "Sidebar",
  components: {
    SidebarLogo,
    MenuItem,
    MenuItemGroup
  },
  computed: {
    menuList() {
      return this.menus;
    },
    activeMenu() {
      let path;
      let activePath;
      const matched = this.getBreadcrumbPathByRoute();

      path = matched.reverse().filter((item) => {
        return !item.hide;
      });

      if (path[0]) {
        activePath = path[0].path;
      }

      return activePath;
    },
    showLogo() {
      return true;
    },
    variables() {
      return require("./../../styles/variables.scss");
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  created() {
    this.menus = routerList;
  },
  mounted() {
    this.$nextTick(() => {
      this.setSidebarWidth();
    });
  },
  data() {
    return {
      sidebar: {
        opened: true,
      },
      menus: [],
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log("[ handleSelect key keyPath ] -> ", key, keyPath);
      const route = this.$route;
      if(keyPath.join("/") === route.fullPath) {
        // not jump
      } else {
        this.$router.push(keyPath.join("/"));
      }
    },
    handleOpen(key, keyPath) {
      key, keyPath;
      // console.log("[ handleOpen ] -> ");
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      key, keyPath;
      // console.log("[ handleClose ] -> ");
      // console.log(key, keyPath);
    },

    // toggleSideBar() {
    //   this.$store.dispatch("app/toggleSideBar");
    // },
    getBreadcrumbPathByRoute() {
      let breadcrumbPath = [];
      let matched = [];
      const flattenPath = this.treeNode2Array(this.menus);
      breadcrumbPath = flattenPath.filter((item) => {
        return item.path === this.$route.path;
      });

      if (breadcrumbPath[0]) {
        matched = breadcrumbPath[0].breadcrumbPath || [];
      }

      return matched;
    },
    treeNode2Array(treeNode) {
      if (!Array.isArray(treeNode)) {
        return [];
      }

      const stack = JSON.parse(JSON.stringify(treeNode));
      const result = [];

      while (stack.length) {
        const first = stack.shift();

        result.push(first);

        if (first.children && first.children.length) {
          stack.unshift(...first.children);
        }
      }

      return result;
    },
    handleTransitionend(event) {
      if (event.propertyName == "width") {
        this.setSidebarWidth();
      }
    },
    setSidebarWidth() {
      // this.$store.dispatch("page/setSidebarWidth", this.$el.clientWidth);
    },
  },
};
</script>

<style lang="scss" scoped></style>
