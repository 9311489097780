import axios from "axios";
const perfix = "/api/BillType";

const add = async (params) => {
  return await axios.post(perfix + '/create', params)
};

const update = async (params) => {
  return await axios.put(perfix + '/update', params)
};

const query = async ({
  page,
  size
}) => {
  return await axios.get(perfix + "/query?page=" + page + "&size=" + size);
};

const remove = async id => {
  return await axios.delete(perfix + "/delete/" + id);
}

const queryAll = async () => {
  return await axios.post(perfix + "/query-all");
}

export default {
  add,
  update,
  query,
  remove,
  queryAll
};