<template>
  <el-submenu :index="item.path">
    <template slot="title">
      <i :class="item.icon"></i>
      <span>{{ item.name }}</span>
    </template>
    <MenuItem
      v-for="(item, index) in item.children"
      :key="index"
      :path="item.path"
      :name="item.name"
      :icon="item.icon"
    />
  </el-submenu>
</template>

<script>
import MenuItem from "./MenuItem";
export default {
  name: "MenuItemGroup",
  components: {
    MenuItem,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
