import layout from "@/layout";

const routerList = [{
    path: '/',
    name: "主页",
    component: () => import("@/views/home"),
    hideInMenu: true
  },
  {
    path: '/bill',
    name: "记账",
    redirect: "/bill/bill-flow",
    icon: "el-icon-s-order",
    component: layout,
    children: [{
      path: 'bill-flow',
      name: "记账流",
      icon: "el-icon-bank-card",
      component: () => import("@/views/bill-flow"),
      meta: {
        name: "记账流",
      }
    }, {
      path: 'account-card',
      name: "账户统计",
      icon: "el-icon-bank-card",
      component: () => import("@/views/account-card"),
      meta: {
        name: "账户统计",
      }
    }]
  },
  {
    path: '/manage',
    name: "数据管理",
    redirect: "/manage/bill-list",
    icon: "el-icon-s-grid",
    component: layout,
    children: [{
        path: 'bill-list',
        name: "账单管理",
        icon: "el-icon-document-copy",
        component: () => import("@/views/bill"),
        meta: {
          name: "账单管理",
        }
      },
      {
        path: 'account-manage',
        name: "账户管理",
        icon: "el-icon-bank-card",
        component: () => import("@/views/account"),
        meta: {
          name: "账户管理",
        }
      },
      {
        path: 'type-manage',
        name: "类型管理",
        icon: "el-icon-menu",
        component: () => import("@/views/type-manage"),
        meta: {
          name: "类型管理",
        }
      }
    ]
  }
]

export default routerList;